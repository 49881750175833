import { useLayoutEffect } from "react";

import { MenuItemsIDMenu } from "../components/Menu";
import { useResize } from "./useResize";

type SectionT = {
  offset: number;
  height: number;
};

export type SectionsQueueT = {
  [key in MenuItemsIDMenu]: SectionT;
};

type useFillSectionsT = (
  setSections: React.Dispatch<React.SetStateAction<{} | SectionsQueueT>>,
  sectionID: MenuItemsIDMenu,
  sectionRef: React.RefObject<HTMLDivElement>
) => void;

export const useFillSections: useFillSectionsT = (
  setSections,
  sectionID,
  sectionRef
) => {
  const { windowWidth, windowHeight } = useResize();

  useLayoutEffect(() => {
    const refOffset = sectionRef.current?.offsetTop;
    const refHeight = sectionRef.current?.offsetHeight;

    if (refOffset) {
      const sectionObject = { offset: refOffset, height: refHeight };
      setSections(sections => ({ ...sections, [sectionID]: sectionObject }));
    }
  }, [setSections, sectionID, sectionRef, windowWidth, windowHeight]);
};
