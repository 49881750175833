import { useIntl } from "gatsby-plugin-intl";
import React, { FC, useState } from "react";

import { BracketItem } from "../../components/BracketItem";
import Container from "../../components/Container";
import Spacer from "../../components/Spacer";
import StoryItem from "../../components/StoryItem";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { StoryWrapper } from "../Homepage/SuccessStories";
import { SuccessStoriesFilterItemT, categories, getStories } from "./data";

const Filter = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${({ theme }): string[] => [theme.media.maxM]} {
    flex-direction: column;
    align-items: center;
  }
`;

const FilterItem = styled.div<ActiveStylePropsT>`
  color: ${({ theme, active }): string =>
    active ? theme.colors.white : theme.colors.gray};
  font-size: ${rem(25)};
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 ${rem(30)};
  ${({ theme }): string[] => [theme.media.maxMD]} {
    font-size: ${rem(20)};
    margin: 0 ${rem(20)};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    margin: ${rem(10)} 0;
  }
`;

type ActiveStylePropsT = {
  active: boolean;
};

const Stories: FC = () => {
  const intl = useIntl();

  const [activeFilter, setActiveFilter] = useState<SuccessStoriesFilterItemT>(
    "all"
  );

  return (
    <Container>
      <Spacer mobileSize="large" size="huge" />
      <Filter>
        {categories.map((category, index) => {
          return (
            <FilterItem
              onClick={(): void => setActiveFilter(category)}
              active={activeFilter === category}
              key={index}
            >
              <BracketItem active={activeFilter === category}>
                {category}
              </BracketItem>
            </FilterItem>
          );
        })}
      </Filter>
      <Spacer size="large" />
      <StoryWrapper>
        {getStories(intl).map(
          (story, index) =>
            (activeFilter === "all" || story.category === activeFilter) && (
              <StoryItem key={index} item={story} />
            )
        )}
      </StoryWrapper>
    </Container>
  );
};

export default React.memo(Stories);
