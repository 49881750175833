import { useIntl } from "gatsby-plugin-intl";
import React, { FC, useState } from "react";

import EdgeBlock from "../../components/EdgeBlock";
import Heading from "../../components/Heading";
import HoverText from "../../components/HoverText";
import Link from "../../components/Link";
import Texture from "../../components/Texture";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { distributeToLines } from "../../utils/distributeToLines";
import { CitiesNameT, CitiesT } from "./Cities";

const Wrapper = styled.div`
  width: 35%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  ${({ theme }): string[] => [theme.media.maxXl]} {
    width: 45%;
  }
  ${({ theme }): string[] => [theme.media.maxLg]} {
    width: 100%;
  }
`;

const HeadingWrapper = styled.div`
  display: block;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    display: none;
  }
`;

const AddressesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: ${rem(400)};
  ${({ theme }): string[] => [theme.media.maxLg]} {
    min-height: 0;
    margin-top: ${rem(30)};
    justify-content: center;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: ${rem(15)};
  letter-spacing: 0.5px;
  line-height: ${rem(24)};
  width: 50%;
  margin-bottom: ${rem(40)};
  height: ${rem(105)};
  text-transform: uppercase;
  align-items: flex-start;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    align-items: center;
    text-align: center;
    height: auto;
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    width: 100%;
  }
`;

const Line = styled.span`
  display: block;
  opacity: 1;
`;

const Directions = styled(Link)`
  font-size: ${rem(16)};
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    color: ${({ theme }): string => theme.colors.primary};
  }
  & > span {
    opacity: 1;
  }

  ${({ theme }): string[] => [theme.media.maxLg]} {
    margin-top: ${rem(10)};
  }
`;

const DirectionsContent = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: ${rem(10)};
    opacity: 1;
  }
  ${({ theme }): string[] => [theme.media.maxLg]} {
    margin-top: ${rem(15)};
    margin-bottom: ${rem(25)};
  }
`;

const Arrow = styled.div<ArrowLineStyleT>`
  width: ${({ visible }): string => (visible ? rem(33) : rem(16))};
  position: relative;
  transition: width 0.2s ease;
`;

const ArrowLine = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: ${rem(2)};
  background-color: ${({ theme }): string => theme.colors.primary};
`;

const Side = styled.div`
  position: absolute;
  right: -1px;
  width: ${rem(10)};
  height: ${rem(2)};
  background-color: ${({ theme }): string => theme.colors.primary};
  transform-origin: right;
`;

const ArrowTop = styled(Side)`
  transform: rotate(40deg) translateY(0.5px);
`;

const ArrowBottom = styled(Side)`
  transform: rotate(-40deg) translateY(-0.5px);
`;

type AddressesT = {
  cities: CitiesT;
  currentCity: CitiesNameT;
};

type ArrowLineStyleT = {
  visible: boolean;
};

const Addresses: FC<AddressesT> = ({ cities, currentCity }) => {
  const intl = useIntl();
  const [directionHover, setDirectionHover] = useState<string | false>(false);

  return (
    <Wrapper>
      <HeadingWrapper>
        <EdgeBlock side="left" top={0} sideDistance={-100}>
          <Texture width={rem(400)} height={rem(150)} />
        </EdgeBlock>
        <Heading
          symbol={`${Object.keys(cities).length}`}
          pre={intl.formatMessage({ id: "home.cities.pre" })}
          title={intl.formatMessage({ id: "home.cities.title" })}
        />
      </HeadingWrapper>
      <AddressesList>
        {cities[currentCity].addresses.map((item, index) => (
          <Address key={index}>
            <div>
              {distributeToLines(item.address).map((line, index) => (
                <Line key={index}>{line}</Line>
              ))}
            </div>
            <Directions externalRoute={item.direction}>
              <DirectionsContent
                onMouseEnter={(): void => setDirectionHover(item.address)}
                onMouseLeave={(): void => setDirectionHover(false)}
              >
                <HoverText>
                  {intl.formatMessage({ id: "home.cities.directions" })}
                </HoverText>
                <Arrow visible={directionHover === item.address}>
                  <ArrowLine />
                  <ArrowTop />
                  <ArrowBottom />
                </Arrow>
              </DirectionsContent>
            </Directions>
          </Address>
        ))}
      </AddressesList>
    </Wrapper>
  );
};

export default React.memo(Addresses);
