import styled from "@emotion/styled";
import React, { FC, useLayoutEffect, useState } from "react";

import { ConcreteTemplate2 } from "../../assets/images/Images";
import BreadcrumbHeading from "../../components/BreadcrumbHeading";
import Container from "../../components/Container";
import EdgeBlock from "../../components/EdgeBlock";
import ScrollDown from "../../components/ScrollDown";
import { useResize } from "../../hooks/useResize";
import { rem } from "../../styling/theme";

const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: ${rem(50)};
  position: relative;
`;

const TextureSide = styled.div<DimensionsStyleProps>`
  width: ${({ width }): string => (width ? width : "auto")};
  height: ${({ height }): string => (height ? height : "auto")};
  background-image: url(${ConcreteTemplate2});
  background-size: auto 100%;
`;

type DimensionsStyleProps = {
  width: string;
  height: string;
};

const Introduction: FC = () => {
  const [containerHeight, setContainerHeight] = useState("100vw");
  const { mobileVersion, windowHeight, windowWidth } = useResize();

  useLayoutEffect(() => {
    setContainerHeight(rem(windowHeight));
  }, [windowHeight]);

  return (
    <Container height={mobileVersion ? rem(500) : containerHeight}>
      <IntroWrapper>
        {!mobileVersion && <ScrollDown />}
        <BreadcrumbHeading items={["home", "successStorries"]} />
      </IntroWrapper>
      <EdgeBlock
        top={0}
        height="100%"
        sideDistance={-windowWidth / 1.7}
        side="right"
      >
        <TextureSide
          height={mobileVersion ? "100%" : rem(windowHeight)}
          width={rem(windowWidth)}
        />
      </EdgeBlock>
    </Container>
  );
};

export default React.memo(Introduction);
