import { useIntl } from "gatsby-plugin-intl";
import React, { FC, useEffect, useRef, useState } from "react";

import { FooterBg, FooterShadowBg } from "../../assets/images/Images";
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import Link from "../../components/Link";
import { MenuItemsIDMenu, getSectionHook } from "../../components/Menu";
import Spacer from "../../components/Spacer";
import { useFillSections } from "../../hooks/useFillSections";
import { useResize } from "../../hooks/useResize";
import { useScroll } from "../../hooks/useScroll";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { HookSectionPropsT } from "../../utils/scrolledSection";
import InTouch from "../general/InTouch";
import { SIDEBAR_WIDTH } from "../SideBar";
import Addresses from "./Addresses";
import Cities, { CitiesNameT, getCities } from "./Cities";

export const BackgroundContainer = styled(Container)`
  background-color: ${({ theme }): string => theme.colors.backgroundGray};
`;

const ContactSection = styled.div<ContactSectionStyleT>`
  width: calc(100% + ${rem(SIDEBAR_WIDTH)});
  background-color: ${({ theme }): string => theme.colors.backgroundGray};
  position: relative;
  z-index: 1;
  margin-left: ${rem(-SIDEBAR_WIDTH)};
  margin-bottom: ${({ footerHeight }): string =>
    footerHeight ? rem(footerHeight) : rem(0)};
  padding-left: ${rem(SIDEBAR_WIDTH)};
  ${({ theme }): string[] => [theme.media.maxLg]} {
    padding-left: 0;
  }
  ${({ theme }): string[] => [theme.media.maxL]} {
    width: 100%;
    margin-left: 0;
  }
`;

const CitiesBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    flex-direction: column;
    align-items: center;
  }
`;

const CitiesWrapper = styled.div`
  width: 65%;
  display: flex;
  position: relative;
  ${({ theme }): string[] => [theme.media.maxXl]} {
    width: 55%;
  }
  ${({ theme }): string[] => [theme.media.maxLg]} {
    width: 100%;
    align-items: center;
    flex-direction: column-reverse;
  }
  &:after {
    content: none;
    display: block;
    position: absolute;
    top: ${rem(15)};
    font-family: "Overpass Mono";
    color: ${({ theme }): string => theme.colors.primary};
    font-size: ${rem(75)};
    text-transform: uppercase;
    z-index: 2;
    ${({ theme }): string[] => [theme.media.maxLg]} {
      content: ">";
    }
  }
`;

const HeadingWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: none;
  justify-content: center;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    display: flex;
  }
`;

const Image = styled.img`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  max-width: ${rem(300)};
  z-index: 1;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    width: 70%;
  }
`;

const Shape = styled.div<ShapeStylePropsT>`
  width: 60%;
  height: ${({ height }): string => rem(height)};
  background-color: ${({ theme }): string => theme.colors.black};
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  ${({ theme }): string[] => [theme.media.maxMac]} {
    width: 64%;
  }
  ${({ theme }): string[] => [theme.media.maxXl]} {
    width: 60%;
  }
  ${({ theme }): string[] => [theme.media.maxLg]} {
    display: none;
  }
  &:after {
    content: ">";
    position: absolute;
    right: ${rem(-19)};
    top: 50%;
    transform: translateY(-50%);
    font-family: "Overpass Mono";
    color: ${({ theme }): string => theme.colors.primary};
    font-size: ${rem(70)};
    text-transform: uppercase;
    z-index: 2;
    ${({ theme }): string[] => [theme.media.maxLg]} {
      content: none;
    }
  }
`;

const ContactWrapper = styled.div`
  top: ${rem(-160)};
  position: relative;
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    top: 0;
    display: table;
    text-align: center;
    font-size: ${rem(20)};
    margin: 0 auto ${rem(60)};
    z-index: 1;
  }
`;

const Item = styled(Link)`
  display: block;
  margin-bottom: ${rem(20)};
  font-size: ${rem(14)};
`;

const InTouchWrapper = styled.div<InTouchWrapperStyleT>`
  position: fixed;
  bottom: 0;
  visibility: ${({ show }): string => (show ? "visible" : "hidden")};
  z-index: 0;
  padding-left: ${rem(SIDEBAR_WIDTH)};
  left: 0;
  right: 0;
  background-image: url(${FooterBg});
  background-size: auto 100%;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    padding-left: 0;
  }
`;

const ContactOverlay = styled.div`
  position: absolute;
  bottom: ${rem(-80)};
  left: 0;
  width: 100%;
  height: ${rem(80)};
  background-image: url(${FooterShadowBg});
  background-size: auto 100%;
`;

type ShapeStylePropsT = {
  height: number;
};

type ContactSectionStyleT = {
  footerHeight?: number;
};

type InTouchWrapperStyleT = {
  show: boolean;
};

const Contact: FC<HookSectionPropsT> = ({ setSections }) => {
  const intl = useIntl();
  const [currentCity, setCurrentCity] = useState<CitiesNameT>("prague");
  const [showFooter, setShowFooter] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperHeight = wrapperRef.current?.offsetHeight;

  const phoneContact = intl.formatMessage({ id: "home.contact.phone" });
  const mailContact = intl.formatMessage({ id: "home.contact.mail" });

  const contactID: MenuItemsIDMenu = "contact";
  const contactRef = useRef<HTMLDivElement>(null);
  useFillSections(setSections, contactID, contactRef);

  const footerRef = useRef<HTMLDivElement>(null);
  const footerHeight = footerRef.current ? footerRef.current.offsetHeight : 0;
  const offsetTop = contactRef.current ? contactRef.current.offsetTop : 0;

  const { currentOffset } = useScroll();
  const { windowHeight } = useResize();

  const offsetBottom = currentOffset + windowHeight;
  const footerOffsetTop = offsetTop + footerHeight + 300;

  useEffect(() => {
    if (offsetBottom > footerOffsetTop) {
      if (!showFooter) {
        setShowFooter(true);
      }
      return;
    }

    if (showFooter) {
      setShowFooter(false);
    }
  }, [footerOffsetTop, offsetBottom, showFooter]);

  return (
    <>
      <ContactSection
        ref={contactRef}
        id={getSectionHook(contactID)}
        footerHeight={footerHeight}
      >
        <Shape height={wrapperHeight ? wrapperHeight : 500} />
        <Container layer={1}>
          <Spacer mobileSize="large" size="huge" />
          <HeadingWrapper>
            <Heading
              symbol={`${Object.keys(getCities(intl)).length}`}
              pre={intl.formatMessage({ id: "home.cities.pre" })}
              title={intl.formatMessage({ id: "home.cities.title" })}
            />
          </HeadingWrapper>
          <div ref={wrapperRef}>
            <CitiesBox>
              <CitiesWrapper>
                <Image
                  src={getCities(intl)[currentCity].image}
                  alt={getCities(intl)[currentCity].name}
                />
                <Cities
                  setCurrentCity={setCurrentCity}
                  currentCity={currentCity}
                />
              </CitiesWrapper>
              <Addresses cities={getCities(intl)} currentCity={currentCity} />
            </CitiesBox>
          </div>
          <ContactWrapper>
            <Item underline="underline" externalRoute={`tel:${phoneContact}`}>
              {phoneContact}
            </Item>
            <Item underline="underline" externalRoute={`mailto:${mailContact}`}>
              {mailContact}
            </Item>
          </ContactWrapper>
        </Container>
        <ContactOverlay />
      </ContactSection>
      <InTouchWrapper ref={footerRef} show={showFooter}>
        <Container>
          <InTouch />
        </Container>
      </InTouchWrapper>
    </>
  );
};

export default React.memo(Contact);
