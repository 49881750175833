import { IntlShape, useIntl } from "gatsby-plugin-intl";
import React, { FC, useRef, useState } from "react";

import {
  BratislavaCity,
  JeseniceCity,
  JihlavaCity,
  KosiceCity,
  NitraCity,
  OlomoucCity,
  OstravaCity,
  PilsenCity,
  PopradCity,
  PragueCity,
  TrencinCity
} from "../../assets/images/Images";
import { PADDING_BASE } from "../../components/Container";
import HoverText from "../../components/HoverText";
import { useResize } from "../../hooks/useResize";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";

const ITEM_WIDTH = 120;
const ITEM_MARGIN = 10;

const Wrapper = styled.ul<PaddingStyleProps & WindowWidthStyle>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  top: ${rem(210)};
  max-height: ${rem(240)};
  font-size: ${rem(15)};
  text-transform: uppercase;
  list-style-type: none;
  z-index: 3;
  ${({ theme }): string[] => [theme.media.maxXl]} {
    max-height: none;
  }
  ${({ theme }): string[] => [theme.media.maxLg]} {
    position: static;
    top: 0;
    display: block;
    width: ${({ width }): string => rem(width)};
    text-align: center;
    padding-left: 0;
    white-space: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const City = styled.li<ActiveCityItemT & WindowWidthStyle>`
  margin-bottom: ${rem(10)};
  opacity: ${({ active }): number => (active ? 1 : 0.5)};
  color: ${({ theme }): string => theme.colors.white};
  font-weight: ${({ active }): number => (active ? 700 : 300)};
  transition: font-weight 0.15s;
  letter-spacing: 1px;
  display: inline-block;
  cursor: pointer;
  margin-right: ${rem(50)};
  ${({ theme }): string[] => [theme.media.maxLg]} {
    margin-left: ${rem(ITEM_MARGIN)};
    margin-right: ${rem(ITEM_MARGIN)};
    width: ${rem(ITEM_WIDTH)};
    &:first-of-type {
      margin-left: ${({ width }): string => rem(width / 2 - ITEM_WIDTH / 2)};
    }

    &:last-child {
      margin-right: ${({ width }): string => rem(width / 2 - ITEM_WIDTH / 2)};
    }
  }
`;

export type CitiesNameT =
  | "prague"
  | "ostrava"
  | "pilsen"
  | "olomouc"
  | "jihlava"
  | "jesenice"
  | "bratislava"
  | "kosice"
  | "poprad"
  | "trencin"
  | "nitra";
type CityAddressT = {
  direction: string;
  address: string;
};
type CityAddressesT = CityAddressT[];
type CityT = {
  id: CitiesNameT;
  name: string;
  image: string;
  addresses: CityAddressesT;
};

export type CitiesT = {
  [key in CitiesNameT]: CityT;
};

type getCitiesT = (intl: IntlShape) => CitiesT;
export const getCities: getCitiesT = intl => {
  // char "|" is a delimeter for new line in address
  return {
    prague: {
      id: "prague",
      name: intl.formatMessage({ id: "home.cities.prague" }),
      image: PragueCity,
      addresses: [
        {
          address: "Křížová 2598/4| Praha 150 00",
          direction: "https://goo.gl/maps/FRavabkWxNUi2RZX8"
        },
        {
          address: "Jeremenkova 411/9| Praha 147 00",
          direction: "https://goo.gl/maps/bk5HYxSXCQXzukzd9"
        }
      ]
    },
    ostrava: {
      id: "ostrava",
      name: intl.formatMessage({ id: "home.cities.ostrava" }),
      image: OstravaCity,
      addresses: [
        {
          address: "Fügnerova 818/8| Ostrava 702 00",
          direction: "https://goo.gl/maps/iXdmVzCPnKbXV8CNA"
        }
      ]
    },
    pilsen: {
      id: "pilsen",
      name: intl.formatMessage({ id: "home.cities.pilsen" }),
      image: PilsenCity,
      addresses: [
        {
          address: "V Bezovce 1523/9| Plzeň 301 00",
          direction: "https://goo.gl/maps/D64QAuvdVEQAJCURA"
        }
      ]
    },
    olomouc: {
      id: "olomouc",
      name: intl.formatMessage({ id: "home.cities.olomouc" }),
      image: OlomoucCity,
      addresses: [
        {
          address: "Horní nám. 365/7| Olomouc 779 00",
          direction: "https://goo.gl/maps/MpkkMtAcvKmiByPu6"
        }
      ]
    },
    jihlava: {
      id: "jihlava",
      name: intl.formatMessage({ id: "home.cities.jihlava" }),
      image: JihlavaCity,
      addresses: [
        {
          address: "Úvoz 173/18| Jihlava 586 01",
          direction: "https://goo.gl/maps/Wk9hfe3NAxYdnfpa7"
        }
      ]
    },
    jesenice: {
      id: "jesenice",
      name: intl.formatMessage({ id: "home.cities.jesenice" }),
      image: JeseniceCity,
      addresses: [
        {
          address: "Cedrová 1236| Jesenice 252 42",
          direction: "https://goo.gl/maps/Z4bcAAnKr9nnkWRt8"
        }
      ]
    },
    bratislava: {
      id: "bratislava",
      name: intl.formatMessage({ id: "home.cities.bratislava" }),
      image: BratislavaCity,
      addresses: [
        {
          address: "Turčianska 1263/19| Bratislava 821 09",
          direction: "https://goo.gl/maps/JUaSMXa1YvT2aih88"
        },
        {
          address: "Hattalova 12b| Bratislava 831 03",
          direction: "https://goo.gl/maps/1VSWtiDfheDNbS7o9"
        }
      ]
    },
    kosice: {
      id: "kosice",
      name: intl.formatMessage({ id: "home.cities.kosice" }),
      image: KosiceCity,
      addresses: [
        {
          address: "Štúrova 22/44| Košice 040 01",
          direction: "https://goo.gl/maps/ky55n8dZv1TNYFJz8"
        }
      ]
    },
    poprad: {
      id: "poprad",
      name: intl.formatMessage({ id: "home.cities.poprad" }),
      image: PopradCity,
      addresses: [
        {
          address: "Nám. sv. Egídia 44| Poprad 058 01",
          direction: "https://goo.gl/maps/Y6Dz1vix4Zqm6xEc7"
        }
      ]
    },
    trencin: {
      id: "trencin",
      name: intl.formatMessage({ id: "home.cities.trencin" }),
      image: TrencinCity,
      addresses: [
        {
          address: "Farská 4| Trenčín 911 01",
          direction: "https://goo.gl/maps/YSfULPJX6SBexPzQ9"
        }
      ]
    },
    nitra: {
      id: "nitra",
      name: intl.formatMessage({ id: "home.cities.nitra" }),
      image: NitraCity,
      addresses: [
        {
          address: "Levická 253/7| Nitra 949 01",
          direction: "https://goo.gl/maps/hu6z8w9aoYPAxKzu8"
        }
      ]
    }
  };
};

type WindowWidthStyle = {
  width: number;
};

type PaddingStyleProps = {
  padding: number;
};

type ActiveCityItemT = {
  active: boolean;
};

type CitiesComponentT = {
  currentCity: CitiesNameT;
  setCurrentCity: React.Dispatch<React.SetStateAction<CitiesNameT>>;
};

const Cities: FC<CitiesComponentT> = ({ setCurrentCity, currentCity }) => {
  const intl = useIntl();
  const [cityItemHover, setCityItemHover] = useState<CitiesNameT | null>(null);
  const horizontalRef = useRef<HTMLUListElement>(null);
  const { windowWidth } = useResize();

  type handleSetCurrentCityT = (id: CitiesNameT, index: number) => void;
  const handleSetCurrentCity: handleSetCurrentCityT = (id, index) => {
    setCurrentCity(id);
    if (horizontalRef.current) {
      if (index === 0) {
        horizontalRef.current.scrollLeft = index;
        return;
      }
      horizontalRef.current.scrollLeft = index * (ITEM_WIDTH + ITEM_MARGIN * 2);
    }
  };

  return (
    <Wrapper
      id="horizontalRef"
      padding={PADDING_BASE}
      ref={horizontalRef}
      width={windowWidth}
    >
      {Object.values(getCities(intl)).map((item, index) => (
        <City
          width={windowWidth}
          onMouseEnter={(): void => handleSetCurrentCity(item.id, index)}
          onMouseLeave={(): void => setCityItemHover(null)}
          active={cityItemHover === item.id || currentCity === item.id}
          onClick={(): void => handleSetCurrentCity(item.id, index)}
          key={index}
        >
          <HoverText>{item.name}</HoverText>
        </City>
      ))}
    </Wrapper>
  );
};

export default React.memo(Cities);
