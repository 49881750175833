import { useIntl } from "gatsby-plugin-intl";
import React, { FC, useState } from "react";

import { ViewMoreThumbnail } from "../assets/images/Images";
import styled from "../styling/styled";
import { theme } from "../styling/theme";
import Button from "./Button";
import {
  Frame,
  HoverStyleProp,
  LinkWrapper,
  Thumbnail,
  Wrapper
} from "./StoryItem";

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 2;
`;

const EmptyThumbnail = styled(Thumbnail)`
  opacity: -1;
`;

const EmptyFrame = styled(Frame)<HoverStyleProp>`
  transform: translate(0px, 0px);
  path {
    stroke: ${({ hover, theme }): string =>
      hover === "true" ? theme.colors.primary : theme.colors.gray};
  }
`;

type StoryItemEmptyT = {
  color?: string;
};

const StoryItemEmpty: FC<StoryItemEmptyT> = ({ color = theme.colors.gray }) => {
  const [hover, setHover] = useState(false);
  const hoverString = hover.toString();
  const intl = useIntl();

  return (
    <Wrapper
      hover={hoverString}
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
    >
      <EmptyFrame color={color} hover={hoverString} />
      <EmptyThumbnail src={ViewMoreThumbnail} />
      <LinkWrapper to={`/success-stories`}>
        <Content>
          <Button
            text={intl.formatMessage({
              id: "home.successStories.descriptions.empty"
            })}
            manualHover={hover}
          />
        </Content>
      </LinkWrapper>
    </Wrapper>
  );
};

export default StoryItemEmpty;
