import { useIntl } from "gatsby-plugin-intl";
import React, { FC, useRef } from "react";

import EdgeBlock from "../../components/EdgeBlock";
import Heading from "../../components/Heading";
import { MenuItemsIDMenu, getSectionHook } from "../../components/Menu";
import Spacer from "../../components/Spacer";
import StoryItem from "../../components/StoryItem";
import StoryItemEmpty from "../../components/StoryItemEmpty";
import Texture from "../../components/Texture";
import { useFillSections } from "../../hooks/useFillSections";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { HookSectionPropsT } from "../../utils/scrolledSection";
import { getHomepageStories } from "../SuccessStories/data";
import { BackgroundContainer } from "./Contact";

export const StoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${rem(1200)};
  margin: 0 auto;
  ${({ theme }): string[] => [theme.media.maxMD]} {
    justify-content: center;
  }
  ${({ theme }): string[] => [theme.media.md]} {
    & > div:nth-of-type(even) {
      top: 100px;
    }
  }
`;

const HeadingWrapper = styled.div`
  margin-bottom: ${rem(80)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    margin: 0 auto ${rem(40)} auto;
    width: 60%;
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    width: 100%;
  }
`;

const SuccessStories: FC<HookSectionPropsT> = ({ setSections }) => {
  const intl = useIntl();

  const storiesID: MenuItemsIDMenu = "stories";
  const storiesRef = useRef<HTMLDivElement>(null);
  useFillSections(setSections, storiesID, storiesRef);

  return (
    <BackgroundContainer
      ref={storiesRef}
      id={getSectionHook(storiesID)}
      layer={1}
    >
      <EdgeBlock side="left" top={0} height="100%">
        <Texture width={rem(500)} height="80%" />
      </EdgeBlock>
      <Spacer size="extraLarge" />
      <HeadingWrapper>
        <Heading
          pre={intl.formatMessage({ id: "home.successStories.pre" })}
          title={intl.formatMessage({ id: "home.successStories.title" })}
        />
      </HeadingWrapper>
      <StoryWrapper>
        {getHomepageStories(intl).map((story, index) => (
          <StoryItem key={index} item={story} />
        ))}
        <StoryItemEmpty />
      </StoryWrapper>
    </BackgroundContainer>
  );
};

export default React.memo(SuccessStories);
