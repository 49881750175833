import { Link, useIntl } from "gatsby-plugin-intl";
import React, { FC, useState } from "react";

import { CaseStudiesShape } from "../assets/images/Images";
import { SuccessStoriesItemT } from "../partials/SuccessStories/data";
import styled from "../styling/styled";
import { rem } from "../styling/theme";
import Button from "./Button";
import InnerHTML from "./InnerHTML";

const SIDE_AREA = 75;
const CAPABAILITY_EASING = "cubic-bezier(0.000, 0.550, 0.700, 1);";

export const Wrapper = styled.div<HoverStyleProp>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  padding-left: ${rem(SIDE_AREA)};
  padding-right: ${rem(SIDE_AREA)};
  margin-bottom: ${rem(200)};
  transform: ${({ hover }): string =>
    hover === "true" ? "translate(-5px, -20px)" : "translate(0, 0)"};
  transition: ${"transform 350ms " + CAPABAILITY_EASING};
  max-width: ${rem(600)};
  ${({ theme }): string[] => [theme.media.maxMD]} {
    width: 100%;
    margin-bottom: ${rem(200)};
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Frame = styled(CaseStudiesShape)<HoverStyleProp & ColorStyleProp>`
  position: absolute;
  left: ${rem(SIDE_AREA)};
  right: ${rem(SIDE_AREA)};
  height: 100%;
  opacity: 0.5;
  z-index: 0;
  transform: ${({ hover }): string =>
    hover === "true" ? "translate(30px, 40px)" : "translate(0px, 0px)"};
  transition: ${"transform 350ms " + CAPABAILITY_EASING};

  path {
    stroke: ${({ color }): string => color};
    stroke-width: 2px;
    fill: transparent;
  }

  ${({ theme }): string[] => [theme.media.maxS]} {
    left: 0;
    right: 0;
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.8);
  z-index: 0;
`;

export const LinkWrapper = styled(Link)`
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  color: inherit;
`;

const Content = styled.div`
  position: absolute;
  left: ${rem(SIDE_AREA / 2)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  bottom: ${rem(100)};
  z-index: 2;
  ${({ theme }): string[] => [theme.media.maxMD]} {
    bottom: ${rem(-120)};
  }
`;

const Technoligies = styled.div`
  display: flex;
  font-size: ${rem(14)};
  letter-spacing: 2px;
  text-transform: uppercase;
  span {
    margin: 0 ${rem(10)};
    text-shadow: 6px 6px 15px rgba(0, 0, 0, 0.6);
  }
  ${({ theme }): string[] => [theme.media.maxLg]} {
    flex-direction: column;
  }
`;

const Title = styled.h2`
  font-size: ${rem(50)};
  font-family: "Overpass Mono";
  letter-spacing: 3px;
  position: relative;
  margin-top: ${rem(15)};
  margin-bottom: ${rem(5)};
  text-shadow: 6px 6px 15px rgba(0, 0, 0, 0.6);
  line-height: 1.1;

  &:before {
    content: "/";
    position: absolute;
    font-size: ${rem(60)};
    transform: translateY(-50%);
    top: 50%;
    left: ${rem(-SIDE_AREA / 2)};
    color: ${({ theme }): string => theme.colors.primary};
  }

  ${({ theme }): string[] => [theme.media.maxS]} {
    font-size: ${rem(40)};
    &:before {
      font-size: ${rem(50)};
      left: ${rem(-SIDE_AREA / 2.5)};
    }
  }
`;

const Description = styled.p`
  font-size: ${rem(16)};
  font-weight: 500;
  line-height: 20px;
  max-width: ${rem(320)};
  text-shadow: 6px 6px 15px rgba(0, 0, 0, 0.6);
`;

type StoryItemPropsT = {
  item: SuccessStoriesItemT;
  className?: string;
};

type ColorStyleProp = {
  color: string;
};

export type HoverStyleProp = {
  hover: string;
};

const StoryItem: FC<StoryItemPropsT> = ({ item, className }) => {
  const { thumbnail, description, title, color, technologies, id } = item;
  const intl = useIntl();
  const [hover, setHover] = useState(false);
  const hoverString = hover.toString();

  return (
    <Wrapper
      hover={hoverString}
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
      className={className}
    >
      <Frame color={color} hover={hoverString} />
      <Thumbnail src={thumbnail} />
      <LinkWrapper to={`/${id}`}>
        <Content>
          <Technoligies>
            {technologies.map((technology, index) => (
              <span key={index}>{technology} </span>
            ))}
          </Technoligies>
          <Title>
            <InnerHTML content={title} />
          </Title>
          <Description>{description}</Description>
          <Button
            text={intl.formatMessage({
              id: "home.successStories.check"
            })}
            manualHover={hover}
          />
        </Content>
      </LinkWrapper>
    </Wrapper>
  );
};

export default StoryItem;
