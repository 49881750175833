import React, { FC } from "react";

import Layout from "../components/Layout";
import Introduction from "../partials/SuccessStories/Introduction";
import Stories from "../partials/SuccessStories/Stories";

const SuccessStories: FC = () => {
  return (
    <Layout
      helmetKey="successStories"
      emptyMenu={true}
      menuIconType="/"
      render={(): React.ReactChild => (
        <>
          <Introduction />
          <Stories />
        </>
      )}
    />
  );
};

export default SuccessStories;
